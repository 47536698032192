import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import FormikControl from "../../../../../../../Formik/FormikControl";
// import UserProfile from "../../../UiLibrary/UserProfile";
import ReactTimeAgo from "react-time-ago";
import MatchService from "../../../../../../../../services/MatchService";
import { toast } from "react-toastify";
import Moment from "moment";
import IMatch from "../../../../../../../Interfaces/Match";
import ITournament from "../../../../../../../Interfaces/Tournament";
import IMatchBody from "../../../../../../../Interfaces/MatchBody";
import IMatchReport from "../../../../../../../Interfaces/MatchReport";
import { UserContext } from "../../../../../../../Context/UserContext";
import PlayPlayUser from "../../../UserComponents/PlayPlayUser";
import { useTranslation } from "react-i18next";

type Props = {
  match: IMatch | undefined;
  tournament: ITournament;
  matchId: string;
  canEditChat: boolean;
  canWatchInfo: boolean;
  isPlayerHome: boolean;
  isPlayerAway: boolean;
  onSetMatch: (match: IMatch) => void;
};

type FormModel = {
  matchIssue: string;
};

const IssuesTab = ({
  match,
  tournament,
  matchId,
  canWatchInfo,
  canEditChat,
  onSetMatch,
  isPlayerHome,
  isPlayerAway,
}: Props) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<FormModel>>(null);
  const [loading, setLoading] = useState(false);
  const [playerRoles, setPlayerRoles] = useState<Record<string, any>>({});

  const reportBoxInitialValues: FormModel = {
    matchIssue: "",
  };

  const buildPlayerRoles = () => {
    const rolesMap: Record<string, any> = {};

    if (tournament && match) {
      // Add owner
      if (tournament.owner) {
        rolesMap[tournament.owner] = { isOwner: true };
      }

      // Add admins
      tournament.admins?.forEach((adminId) => {
        rolesMap[adminId] = { ...(rolesMap[adminId] || {}), isAdmin: true };
      });

      // Add players and teams
      match.result.scores.forEach((score, index) => {
        const teamRole = index === 0 ? "home" : "away";
        score.playerResults.forEach((player) => {
          rolesMap[player.playerId] = {
            ...(rolesMap[player.playerId] || {}),
            team: teamRole,
          };
        });

        // Add leader if available
        if (score.teamId) {
          const teamLeader = tournament.teams?.find(
            (team) => team.partyId === score.teamId
          )?.leader;
          if (teamLeader) {
            rolesMap[teamLeader] = {
              ...(rolesMap[teamLeader] || {}),
              isLeader: true,
              team: teamRole,
            };
          }
        }
      });
    }

    setPlayerRoles(rolesMap);
  };

  useEffect(() => {
    buildPlayerRoles();
  }, [match, tournament]);

  const reportIssue = (values: FormModel) => {
    if (!user?.id) {
      return;
    }
    if (values.matchIssue.length === 0) {
      return;
    }
    setLoading(true);
    const report: IMatchReport = {
      messages: values.matchIssue,
      playerId: user?.id,
      timestamp: new Date(),
    };
    const data: IMatchBody = {
      result: {
        reports: report,
      },
    };
    MatchService.updateMatch(matchId, data)
      .then((match) => {
        onSetMatch(match);
        formikRef?.current?.resetForm();
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="columns tabs-body">
      {/* Header */}
      <div className="column col-12">
        <div className="space-divider-20"></div>
        <h5 className="skp-text-primary">Messages</h5>
        {canEditChat && (
          <Formik
            onSubmit={reportIssue}
            initialValues={reportBoxInitialValues}
            innerRef={formikRef}
          >
            {({ resetForm }) => (
              <Form>
                <FormikControl
                  control="textarea"
                  label="Leave a message"
                  name="matchIssue"
                />
                <button
                  disabled={loading}
                  type="submit"
                  className="skp-btn skp-btn-primary float-right"
                >
                  Add message
                </button>
              </Form>
            )}
          </Formik>
        )}
      </div>
      {/* Body */}
      <>
        {canWatchInfo && (
          <div className="column col-12">
            {/* Display issue */}
            <div className="columns">
              <div className="column col-12">
                {(!match?.result.reports ||
                  match?.result.reports.length === 0) && (
                  <div className="columns skp-text-primary text-center">
                    <div className="space-divider-20"></div>
                    <div className="column col-12">
                      <h1>
                        <i className="las la-angry"></i>
                      </h1>
                    </div>
                    <div className="column col-12">
                      There are currently no issues reported for this match!
                    </div>
                  </div>
                )}
                {match?.result.reports && match?.result.reports.length > 0 && (
                  <dl className="bordered-list">
                    {match?.result?.reports
                      .slice(0)
                      .reverse()
                      .map((report, index) => (
                        <dd className="columns" key={"chat" + index}>
                          <div className="column col-12">
                            <div className="columns">
                              <div className="column col-12 playnplay-match">
                                <div className="user-profile">
                                  <PlayPlayUser
                                    playerId={report.playerId}
                                    hideCheckin={true}
                                  >
                                    {playerRoles[report.playerId]?.isOwner && (
                                      <span className="skp-chip skp-text-xxsmall status-captain mr-2">
                                        {t("common.owner")}
                                      </span>
                                    )}
                                    {playerRoles[report.playerId]?.isAdmin && (
                                      <span className="skp-chip skp-text-xxsmall status-captain mr-2">
                                        {t("common.admin")}
                                      </span>
                                    )}
                                    {tournament.playerType === 1 &&
                                      playerRoles[report.playerId]
                                        ?.isLeader && (
                                        <span className="skp-chip skp-text-xxsmall status-captain mr-2">
                                          Leader
                                        </span>
                                      )}
                                    {tournament.playerType === 1 &&
                                      playerRoles[report.playerId]?.team ===
                                        "home" && (
                                        <span className="skp-chip skp-text-xxsmall status-1 mr-2">
                                          Team 1
                                        </span>
                                      )}
                                    {tournament.playerType === 1 &&
                                      playerRoles[report.playerId]?.team ===
                                        "away" && (
                                        <span className="skp-chip skp-text-xxsmall status-2 mr-2">
                                          Team 2
                                        </span>
                                      )}{" "}
                                    <span className="skp-text-primary skp-text-small">
                                      <ReactTimeAgo
                                        date={new Date(report?.timestamp)}
                                      />{" "}
                                      {" - "}
                                      {Moment(report?.timestamp).format(
                                        "MMM DD, hh:mm A"
                                      )}
                                    </span>
                                  </PlayPlayUser>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="column col-12 skp-text-primary skp-text-default"
                            style={{ padding: "0 0 0 75px" }}
                          >
                            <span>{report.messages}</span>
                          </div>
                        </dd>
                      ))}
                  </dl>
                )}
              </div>
            </div>
          </div>
        )}
      </>
      {!canWatchInfo && (
        <div className="column col-12">
          <div className="columns">
            <div className="column col-12">
              <div className="columns skp-text-primary text-center">
                <div className="space-divider-20"></div>
                <div className="column col-12">
                  <h1>
                    <i className="las la-angry"></i>
                  </h1>
                </div>
                <div className="column col-12">
                  You are not allowed to see the information from this match.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IssuesTab;
