import Match from "../../../UiLibrary/MatchComponents/Match";
import "./tournaments-tab.scss";
import MultiMatch from "../../../UiLibrary/MatchComponents/MultiMatch";
import { useState, useEffect, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ITournament from "../../../Interfaces/Tournament";
import IMatchTournamentSeed from "../../../Interfaces/MatchTournamentSeed";
import GenerateBracketRounds from "../../../Hooks/GenerateBracketRounds";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament | undefined;
  matches: Array<IMatchTournamentSeed>;
  onLogin: () => void;
  onSetMatch: (matchSeed: IMatchTournamentSeed) => void;
};

const MatchesTab = (props: Props) => {
  const { matches, tournament } = props;
  const { t } = useTranslation();
  const [paginatedItems, setPaginatedItems] = useState<IMatchTournamentSeed[]>(
    []
  );
  const [activeRounds, setActiveRounds] = useState("winners");
  const [activeRound, setActiveRound] = useState<{
    title: string;
    seeds: IMatchTournamentSeed[];
  }>();

  const [rounds, setRounds] = useState(GenerateBracketRounds.generate(matches));

  const selectRound = useCallback(
    (roundId: number) => {
      const rounds = GenerateBracketRounds.generate(matches);
      setPaginatedItems([]);
      if (!tournament) {
        return;
      }

      if (rounds.loosers.length === 0) {
        setActiveRounds("winners");
      }

      setTimeout(() => {
        const round = rounds[activeRounds][roundId];
        let items: IMatchTournamentSeed[] = [];
        const index = round?.seeds?.length >= 10 ? 10 : round?.seeds?.length;
        for (let i = 0; i < index; i++) {
          items.push(round.seeds[i]);
        }
        setPaginatedItems(items);
        setActiveRound(round);
      }, 10);

      const itemObject = { selectedRound: roundId };
      localStorage.setItem(tournament?.id, JSON.stringify(itemObject));
    },
    [tournament, matches, activeRounds]
  );

  useEffect(() => {
    if (!tournament?.id) {
      return;
    }
    const localItem = localStorage.getItem(tournament.id);
    const tournamentStorage = localItem ? JSON.parse(localItem) : [];
    const roundId = tournamentStorage?.selectedRound
      ? tournamentStorage?.selectedRound
      : 0;
    selectRound(roundId);
  }, [tournament?.id, selectRound]);

  const fetchMoreData = () => {
    if (!activeRound) {
      return;
    }
    setTimeout(() => {
      setPaginatedItems([
        ...paginatedItems,
        activeRound.seeds[paginatedItems.length],
      ]);
    }, 100);
  };

  const viewDetails = (seed: IMatchTournamentSeed) => {
    props.onSetMatch(seed);
  };

  useEffect(() => {
    const removeLevels = (levelsToRemove) => {
      setRounds((prevRounds) => {
        const updatedRounds = { ...prevRounds }; // Copia immutabile dell'oggetto

        // Rimuovi gli ultimi 'levelsToRemove' elementi dall'array 'winners'
        updatedRounds.winners.splice(-levelsToRemove, levelsToRemove);

        // Accedi all'ultimo elemento rimasto nell'array 'winners'
        const lastWinner =
          updatedRounds.winners[updatedRounds.winners.length - 1];

        // Modifica il campo 'seeds' dell'ultimo elemento
        if (lastWinner && Array.isArray(lastWinner.seeds)) {
          lastWinner.seeds = lastWinner.seeds.map((seed) => {
            const { nextMatch, ...rest } = seed; // Rimuovi 'nextMatch' usando destrutturazione
            return rest;
          });
        }

        return updatedRounds; // Restituisci l'oggetto aggiornato
      });
    };

    const levelsToRemove = tournament?.hideLevel;

    if (levelsToRemove) removeLevels(levelsToRemove);
  }, []);

  return (
    <div className="column col-12">
      {/* No matches */}
      {(tournament?.matches?.length === 0 || rounds.winners.length === 0) && (
        <div className="columns skp-text-light text-center">
          <div className="space-divider"></div>
          <div className="column col-12">
            <h1>
              <i className="las la-chess"></i>
            </h1>
          </div>
          <div className="column col-12">
            <h5>{t("matches.noMatches")}</h5>
          </div>
          <div className="column col-12">{t("matches.tournamentNoSeed")}</div>
        </div>
      )}

      {rounds.loosers.length > 0 && (
        <div className="columns tabs-header">
          <div className="column col-12">
            <ul className="tab tab-block skp-text-light">
              <li
                className={`tab-item ${
                  activeRounds === "winners" ? "active" : ""
                }`}
              >
                <a onClick={() => setActiveRounds("winners")}>Winners</a>
              </li>
              <li
                className={`tab-item ${
                  activeRounds === "loosers" ? "active" : ""
                }`}
              >
                <a onClick={() => setActiveRounds("loosers")}>Losers</a>
              </li>
            </ul>
          </div>
        </div>
      )}

      {activeRounds === "winners" &&
        tournament?.matches.length > 0 &&
        rounds && (
          <>
            <div className="columns">
              <div className="space-divider"></div>
              <div className="column col-12 text-center">
                {rounds.winners.map((round, index) => (
                  <button
                    key={round.title}
                    style={{ marginRight: 5, marginTop: 10 }}
                    className={`skp-btn ${
                      activeRound?.title === round.title
                        ? "skp-btn-primary"
                        : "skp-btn-secondary"
                    } skp-text-small`}
                    onClick={() => selectRound(index)}
                  >
                    {round.title}
                  </button>
                ))}
              </div>
              <div className="space-divider-20"></div>
            </div>

            <div className="columns">
              <div className="column col-12">
                <div className="column col-12">
                  <dl className="bordered-list">
                    <InfiniteScroll
                      dataLength={paginatedItems.length}
                      scrollableTarget="scrollableDiv"
                      next={fetchMoreData}
                      hasMore={
                        (activeRound &&
                          activeRound?.seeds.length > paginatedItems.length) ||
                        false
                      }
                      style={{ overflow: "hidden" }}
                      loader={
                        <>
                          <div className="space-divider-20"></div>
                          <div className="loading"></div>
                        </>
                      }
                      scrollThreshold={0.1}
                      endMessage={
                        <>
                          {paginatedItems.length >= 10 && (
                            <>
                              <p
                                className="text-center skp-text-light m-2"
                                style={{ textAlign: "center" }}
                              >
                                {t("common.end")}
                              </p>
                            </>
                          )}
                        </>
                      }
                    >
                      <TransitionGroup className="todo-list">
                        {tournament &&
                          paginatedItems.map((seed, index) => (
                            <CSSTransition
                              key={`winners-${seed.id}-${index}`}
                              timeout={300}
                              classNames="item"
                            >
                              <dd
                                onClick={() => viewDetails(seed)}
                                style={{ height: 120 }}
                              >
                                <>
                                  {seed?.matchesId?.length === 1 && (
                                    <>
                                      <div className="skp-text-label skp-text-light">
                                        {t("matches.match")} {seed.id}
                                      </div>
                                      <Match
                                        matchId={seed.matchesId[0]}
                                        showStatus={true}
                                        tournament={tournament}
                                      />
                                    </>
                                  )}

                                  {seed.matchesId?.length > 1 && (
                                    <>
                                      <div className="skp-text-label skp-text-light">
                                        {t("matches.match")} {seed.id} -{" "}
                                        {t("matches.bestOf", {
                                          count: seed.matchesId?.length,
                                        })}
                                      </div>
                                      <MultiMatch
                                        tournament={tournament}
                                        match={seed}
                                        matchId={seed.matchesId[0]}
                                        showStatus={true}
                                      />
                                    </>
                                  )}
                                </>
                              </dd>
                            </CSSTransition>
                          ))}
                      </TransitionGroup>
                    </InfiniteScroll>
                  </dl>
                </div>
              </div>
            </div>

            <div className="space-divider-40"></div>
          </>
        )}

      {activeRounds === "loosers" &&
        tournament?.matches.length > 0 &&
        rounds && (
          <>
            <div className="columns">
              <div className="space-divider"></div>
              <div className="column col-12 text-center">
                {rounds.loosers.map((round, index) => (
                  <button
                    key={`loosers-${round.title}`}
                    style={{ marginRight: 5, marginTop: 10 }}
                    className={`skp-btn ${
                      activeRound?.title === round.title
                        ? "skp-btn-primary"
                        : "skp-btn-secondary"
                    } skp-text-small`}
                    onClick={() => selectRound(index)}
                  >
                    {round.title}
                  </button>
                ))}
              </div>
              <div className="space-divider-20"></div>
            </div>

            <div className="columns">
              <div className="column col-12">
                <div className="column col-12">
                  <dl className="bordered-list">
                    <InfiniteScroll
                      dataLength={paginatedItems.length}
                      scrollableTarget="scrollableDiv"
                      next={fetchMoreData}
                      hasMore={
                        (activeRound &&
                          activeRound?.seeds.length > paginatedItems.length) ||
                        false
                      }
                      style={{ overflow: "hidden" }}
                      loader={
                        <dd>
                          <div className="loading"></div>
                        </dd>
                      }
                      scrollThreshold={"100px"}
                      endMessage={
                        <>
                          {paginatedItems.length >= 10 && (
                            <>
                              <p
                                className="text-center skp-text-light m-2"
                                style={{ textAlign: "center" }}
                              >
                                {t("common.end")}
                              </p>
                            </>
                          )}
                        </>
                      }
                    >
                      {tournament &&
                        paginatedItems.map((seed, index) => (
                          <dd
                            key={`loosers-${seed.id}-${index}`}
                            onClick={() => viewDetails(seed)}
                          >
                            <>
                              {seed?.matchesId?.length === 1 && (
                                <>
                                  <div className="skp-text-label skp-text-light">
                                    {t("matches.match")} {seed.id}
                                  </div>
                                  <Match
                                    matchId={seed.matchesId[0]}
                                    showStatus={true}
                                    tournament={tournament}
                                  />
                                </>
                              )}

                              {seed.matchesId?.length > 1 && (
                                <>
                                  <div className="skp-text-label skp-text-light">
                                    {t("matches.match")} {seed.id} -{" "}
                                    {t("matches.bestOf", {
                                      count: seed.matchesId?.length,
                                    })}
                                  </div>
                                  <MultiMatch
                                    tournament={tournament}
                                    match={seed}
                                    matchId={seed.matchesId[0]}
                                    showStatus={true}
                                  />
                                </>
                              )}
                            </>
                          </dd>
                        ))}
                    </InfiniteScroll>
                  </dl>
                </div>
              </div>
            </div>

            <div className="space-divider-40"></div>
          </>
        )}
    </div>
  );
};

export default MatchesTab;
