import { Formik, Form, Field, FormikProps } from "formik";
import { useContext, useEffect, useRef } from "react";
import * as Yup from "yup";

import IMatch from "../../../../../Interfaces/Match";
import ITournament from "../../../../../Interfaces/Tournament";
import { UserContext } from "../../../../../Context/UserContext";
import PlayPlayUser from "../UserComponents/PlayPlayUser";
import { useTranslation } from "react-i18next";
import MatchTeamName from "../../../../../UiLibrary/MatchComponents/MatchTeamName";

type Props = {
  match: IMatch;
  isAdmin: boolean;
  loading: boolean;
  tournament: ITournament;
  onReportScore: (data: FormModel) => void;
};

interface FormModel {
  teamHomeScore: number | null;
  teamAwayScore: number | null;
}

const MatchScore = (props: Props) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { match, isAdmin, loading, tournament } = props;
  const isTournamentWithGroups = tournament.groups
    ? tournament.groups.length > 0
    : false;

  let initialValues: FormModel = {
    teamHomeScore: null,
    teamAwayScore: null,
  };

  const validationField = (field: string) => {
    if (isTournamentWithGroups) {
      return Yup.number().required();
    } else {
      return Yup.number()
        .required()
        .notOneOf([Yup.ref(field)]);
    }
  };

  const validationSchema = Yup.object({
    teamHomeScore: validationField("teamAwayScore"),
    teamAwayScore: validationField("teamHomeScore"),
  });

  const formikRef = useRef<FormikProps<FormModel>>(null);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "teamHomeScore",
        Number(match?.result?.scores[0].score)
          ? Number(match?.result?.scores[0].score)
          : 0
      );
      formikRef.current.setFieldValue(
        "teamAwayScore",
        Number(match?.result?.scores[1].score)
          ? Number(match?.result?.scores[1].score)
          : 0
      );
    }
  }, [match, user]);

  const onSubmit = (values: FormModel) => {
    props.onReportScore(values);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            <div className="columns">
              <div className="column col-5 col-xs-12">
                <div className="columns">
                  <div className="column col-10 text-right text-left-xs playnplay-match">
                    {tournament?.playerType === 0 && (
                      <div className="user-profile row-reverse">
                        <PlayPlayUser
                          playerId={match?.result?.scores[0].teamId}
                          hideCheckin={true}
                        ></PlayPlayUser>
                      </div>
                    )}
                    {tournament?.playerType === 1 && (
                      <MatchTeamName
                        tournament={tournament}
                        teamId={match?.result?.scores[0].teamId}
                        reverse={true}
                        isHome={true}
                        isPlayPlay={true}
                      />

                      // <MatchTeamName
                      //   tournament={tournament}
                      //   teamId={match?.result?.scores[0].teamId}
                      //   reverse={true}
                      // />
                    )}
                  </div>
                  <div className="column col-2 flex-centered skp-text-primary skp-text-default">
                    <div
                      className={
                        errors.teamHomeScore && touched.teamHomeScore
                          ? "skp-form-control skp-form-control-error"
                          : "skp-form-control"
                      }
                    >
                      <Field
                        name="teamHomeScore"
                        id="teamHomeScore"
                        disabled={!(match?.status === 1) || !isAdmin}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="column col-2 flex-centered skp-text-light hide-xs">
                :
              </div>
              <div className="column col-5 col-xs-12">
                <div className="columns">
                  <div className="column col-2 flex-centered skp-text-primary skp-text-default">
                    <div
                      className={
                        errors.teamAwayScore && touched.teamAwayScore
                          ? "skp-form-control skp-form-control-error"
                          : "skp-form-control"
                      }
                    >
                      <Field
                        name="teamAwayScore"
                        id="teamAwayScore"
                        disabled={!(match?.status === 1) || !isAdmin}
                      />
                    </div>
                  </div>
                  <div className="column col-10 text-left playnplay-match">
                    {tournament?.playerType === 0 && (
                      <div className="user-profile">
                        <PlayPlayUser
                          playerId={match?.result?.scores[1].teamId}
                          hideCheckin={true}
                        ></PlayPlayUser>
                      </div>
                    )}
                    {tournament?.playerType === 1 && (
                      <MatchTeamName
                        tournament={tournament}
                        teamId={match?.result?.scores[1].teamId}
                        isHome={false}
                        isPlayPlay={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="column col-12 text-center">
                <div className="space-divider-20"></div>
                {isAdmin && (
                  <button
                    disabled={match?.status !== 1 || !isValid || loading}
                    className="skp-btn skp-btn-primary"
                    type="submit"
                  >
                    {loading ? (
                      <span className="loading"></span>
                    ) : (
                      "Report Score"
                    )}
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MatchScore;
