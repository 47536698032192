import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import MatchService from "../../../services/MatchService";
import MatchTeamName from "./MatchTeamName";
import UserProfileWithDetailsModal from "../User/UserProfileWithDetailsModal";
import Tournament from "../../Interfaces/Tournament";
import IMatch from "../../Interfaces/Match";
import EMatchStatus from "../../../enums/MatchStatus";
import { useTranslation } from "react-i18next";

type Props = {
  matchId: string;
  showStatus?: boolean;
  gameId?: number;
  tournament: Tournament;
};

const Match = ({ matchId, showStatus, gameId, tournament }: Props) => {
  const [singleMatch, setMatch] = useState<IMatch>();
  const { t } = useTranslation();
  let playerHomeWinner: boolean = false;
  let playerAwayWinner: boolean = false;
  let teamHomeId!: string;
  let teamAwayId!: string;

  const playerType = tournament.custom ? 0 : tournament?.playerType;

  if (singleMatch && singleMatch.result.scores) {
    playerHomeWinner =
      singleMatch?.result?.scores[0].teamId === singleMatch?.winner &&
      singleMatch?.status === 3;
    playerAwayWinner =
      singleMatch?.result?.scores[1].teamId === singleMatch?.winner &&
      singleMatch?.status === 3;
    teamHomeId = singleMatch?.result?.scores[0].teamId;
    teamAwayId = singleMatch?.result?.scores[1].teamId;
  }

  useEffect(() => {
    if (matchId) {
      MatchService.getSingleMatch(matchId).then((Rmatch) => {
        setMatch(Rmatch);
      });
    }
    return () => {
      setMatch(undefined);
    };
  }, [matchId]);

  const textStatus = () => {
    switch (singleMatch?.status) {
      case 0:
        return "skp-text-light";
      case 1:
        return "skp-text-accent";
      case 2:
        return "skp-text-secondary";
      case 3:
        return "skp-text-ternary";

      default:
        return "skp-text-light";
    }
  };

  return (
    <>
      {singleMatch && singleMatch.result.scores && (
        <div className="columns">
          <div
            className={`column col-12 text-center skp-text-xxsmall ${textStatus()}`}
          >
            {(gameId || gameId === 0) && (
              <div className="skp-text-small skp-text-primary">{`${t(
                "common.game"
              )} ${gameId + 1}`}</div>
            )}
            {showStatus && <div>{t(EMatchStatus[singleMatch.status])}</div>}
          </div>
          <div className="column col-5 col-xs-12">
            <div className="columns">
              <div className="column col-11">
                {playerType === 0 && (
                  <UserProfileWithDetailsModal
                    userId={teamHomeId}
                    reverse={true}
                    tournament={tournament}
                  >
                    {playerHomeWinner && (
                      <img
                        alt="logo-winner"
                        style={{ width: 30 }}
                        src="https://img.icons8.com/external-flaticons-flat-flat-icons/344/external-winner-achievements-flaticons-flat-flat-icons-2.png"
                      />
                    )}
                  </UserProfileWithDetailsModal>
                )}
                {/* Team Name */}
                {playerType === 1 && (
                  <MatchTeamName
                    tournament={tournament}
                    teamId={singleMatch?.result?.scores[0].teamId}
                    reverse={true}
                    isHome={true}
                    isPlayPlay={false}
                  />
                )}
                {/* End Team Name */}
              </div>
              <div className="column col-1 flex-centered skp-text-primary skp-text-default">
                {singleMatch?.result?.scores[0].score
                  ? singleMatch?.result?.scores[0].score
                  : 0}
              </div>
            </div>
          </div>
          <div className="column col-2 flex-centered skp-text-light hide-xs">
            :
          </div>
          <div className="column col-5 col-xs-12">
            <div className="columns">
              {!isMobile && (
                <div className="column col-1 flex-centered skp-text-primary skp-text-default">
                  {singleMatch?.result?.scores[1].score
                    ? singleMatch?.result?.scores[1].score
                    : 0}
                </div>
              )}
              <div className="column col-11 text-left">
                {playerType === 0 && (
                  <UserProfileWithDetailsModal
                    userId={teamAwayId}
                    tournament={tournament}
                  >
                    {playerAwayWinner && (
                      <img
                        alt="logo-winner"
                        style={{ width: 30 }}
                        src="https://img.icons8.com/external-flaticons-flat-flat-icons/344/external-winner-achievements-flaticons-flat-flat-icons-2.png"
                      />
                    )}
                  </UserProfileWithDetailsModal>
                )}
                {/* Team Name */}
                {playerType === 1 && (
                  <MatchTeamName
                    tournament={tournament}
                    teamId={teamAwayId}
                    isHome={false}
                    isPlayPlay={false}
                  />
                )}
                {/* End Team Name */}
              </div>
              {isMobile && (
                <div className="column col-1 flex-centered skp-text-primary skp-text-default">
                  {singleMatch?.result?.scores[1].score
                    ? singleMatch?.result?.scores[1].score
                    : 0}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Match;
