import "./tournaments-tab.scss";
import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { Bracket } from "react-brackets";
import CustomSeed from "../../../UiLibrary/CustomSeed";
import KeyCloackService from "../../../../services/KeyCloackService";
import EventBus from "react-native-event-bus";
import IMatchTournamentSeed from "../../../Interfaces/MatchTournamentSeed";
import ITournament from "../../../Interfaces/Tournament";
import GenerateBracketRounds from "../../../Hooks/GenerateBracketRounds";
import TournamentService from "../../../../services/TournamentService";
import { UserContext } from "../../../Context/UserContext";
import Modal from "../../../UiLibrary/Modal";
import ConfigBracketRoundModal from "./BracketTabs/Modals/ConfigBracketRoundModal";
import ITournamentBracketRound from "../../../Interfaces/TournamentBracketRound";
import { EventsContext } from "../../../Context/EventsContext";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";
import { isFunction } from "formik";

type Props = {
  tournament: ITournament;
  matches: IMatchTournamentSeed[];
  onSetMatch: (data: any) => void;
  onLogin: () => void;
  loadTournament: (tournament: ITournament | undefined) => void;
};

interface IRoundModal {
  show: boolean;
  roundIndex: number;
  rounds: ITournamentBracketRound[];
}

const BracketsTab = (props: Props) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { tournament, matches } = props;
  const [rounds, setRounds] = useState(GenerateBracketRounds.generate(matches));
  const bracketRef = useRef<HTMLDivElement>(null);

  const [showRoundModal, setShowRoundModal] = useState<IRoundModal>({
    show: false,
    roundIndex: 0,
    rounds: [],
  });

  const [isFullScreen, setIsFullScreen] = useState(false);

  const isAdmin =
    tournament?.owner === user?.id ||
    (user?.roles && user.roles.includes("TournamentAdmin"))
      ? true
      : false;
  const tournamentNotStarted: boolean = tournament?.status === 0;

  const [activeRounds, setActiveRounds] = useState("winners");

  const viewDetails = useCallback(
    (data: any) => {
      props.onSetMatch(data);
    },
    [props]
  );

  useEffect(() => {
    const viewDetailsListener = EventBus.getInstance().addListener(
      "SHOW_MATCH_DETAILS",
      (data: any) => {
        viewDetails(data);
      }
    );

    return () => {
      EventBus.getInstance().removeListener(viewDetailsListener);
    };
  }, [viewDetails]);

  const changeRounds = (value: string) => {
    setActiveRounds(value);
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleSwipeChange = (index: number) => {
    setTabIndex(index);
  };

  const onRoundClick = (e, roundIndex, bracket) => {
    e.preventDefault();
    e.stopPropagation();
    setShowRoundModal({
      show: true,
      roundIndex: roundIndex,
      rounds: bracket === "winners" ? rounds.winners : rounds.loosers,
    });
  };

  const onCloseModal = () => {
    setShowRoundModal({
      show: false,
      roundIndex: 0,
      rounds: [],
    });
  };

  const refreshBracket = () => {
    TournamentService.getTournament(tournament?.id).then((t) => {
      props.loadTournament(t);
    });
  };

  const toggleFullscreen = () => {
    const tournamentId = tournament?.id; // Assicurati che `tournament` sia definito e abbia un `id`.

    if (tournamentId) {
      // Crea il link per la nuova tab
      const fullscreenUrl = `${window.location.origin}/tournament/${tournamentId}/brackets/fullscreen`;

      // Apri la nuova tab
      window.open(fullscreenUrl, "_blank");
    } else {
      console.error("Tournament ID is not defined.");
    }
  };

  useEffect(() => {
    const removeLevels = (levelsToRemove) => {
      setRounds((prevRounds) => {
        const updatedRounds = { ...prevRounds }; // Copia immutabile dell'oggetto

        // Rimuovi gli ultimi 'levelsToRemove' elementi dall'array 'winners'
        updatedRounds.winners.splice(-levelsToRemove, levelsToRemove);

        // Accedi all'ultimo elemento rimasto nell'array 'winners'
        const lastWinner =
          updatedRounds.winners[updatedRounds.winners.length - 1];

        // Modifica il campo 'seeds' dell'ultimo elemento
        if (lastWinner && Array.isArray(lastWinner.seeds)) {
          lastWinner.seeds = lastWinner.seeds.map((seed) => {
            const { nextMatch, ...rest } = seed; // Rimuovi 'nextMatch' usando destrutturazione
            return rest;
          });
        }

        return updatedRounds; // Restituisci l'oggetto aggiornato
      });
    };

    const levelsToRemove = tournament.hideLevel;

    if (levelsToRemove) removeLevels(levelsToRemove);
  }, []);

  return (
    <>
      {tournament?.matches?.length === 0 && (
        <div className="container">
          <div className="columns skp-text-light text-center">
            <div className="space-divider"></div>
            <div className="column col-12">
              <h1>
                <i className="las la-stream"></i>
              </h1>
            </div>
            <div className="column col-12">
              <h5>{t("bracket.noBracket")}</h5>
            </div>
            <div className="column col-12">{t("bracket.tournamentNoSeed")}</div>
          </div>
        </div>
      )}

      {tournament?.matches.length > 0 && (
        <div className="brackets-tab" ref={bracketRef}>
          {rounds.loosers.length > 0 && (
            <div className="columns tabs-header">
              <div className="column col-12">
                <ul className="tab skp-text-light">
                  <li
                    className={`tab-item pointer ${
                      activeRounds === "winners" ? "active" : ""
                    }`}
                  >
                    <a onClick={() => changeRounds("winners")}>Winners</a>
                  </li>
                  <li
                    className={`tab-item pointer ${
                      activeRounds === "loosers" ? "active" : ""
                    }`}
                  >
                    <a onClick={() => changeRounds("loosers")}>Losers</a>
                  </li>
                  {/*
                TOLTO FULLSCREEN E RESET BRACKET E TOLTO DRAGGABLE
                  <li className="tab-item push-right skp-text-primary pointer" style={{"display":"flex"}}>
                      <a onClick={() => toggleFullscreen()}>
                        <i className="las la-expand"></i> Fullscreen
                      </a>
                      <a onClick={() => refreshBracket()}>
                          {t('bracket.resetBracketPosition')}
                      </a>
                  </li>
                */}
                </ul>
              </div>
            </div>
          )}
          {/*
                TOLTO FULLSCREEN E RESET BRACKET E TOLTO DRAGGABLE
          {!(rounds.loosers.length > 0) && <div className="columns tabs-header">
            <div className="column col-12">
              <ul className="tab skp-text-light">
                <li className="tab-item push-right skp-text-primary pointer" style={{"display":"flex"}}>
                    <a onClick={() => toggleFullscreen()}>
                      <i className="las la-expand"></i> Fullscreen
                    </a>
                    <a onClick={() => refreshBracket()}>
                        {t('bracket.resetBracketPosition')}
                    </a>
                </li>
              </ul>
            </div>
          </div>}
          */}
          {/*<Draggable>*/}
          <div className="columns">
            <div className="column col-12">
              {rounds.winners.length > 0 && activeRounds === "winners" && (
                <Bracket
                  rounds={rounds.winners}
                  renderSeedComponent={(props) => (
                    <CustomSeed
                      {...props}
                      isFullScreen={isFullScreen}
                      filteredRounds={rounds}
                    />
                  )}
                  swipeableProps={{
                    enableMouseEvents: true,
                    animateHeight: true,
                    index: tabIndex,
                    onChangeIndex: handleSwipeChange,
                  }}
                  roundTitleComponent={(title: string, roundIndex: number) => {
                    return (
                      <div>
                        <h5 className="skp-text-primary text-center">
                          {title}{" "}
                          {isAdmin && tournamentNotStarted && (
                            <i
                              className="las la-cog"
                              onClick={(e) =>
                                onRoundClick(e, roundIndex, "winners")
                              }
                            ></i>
                          )}
                        </h5>
                        <div className="skp-text-small skp-text-light text-center">
                          {t("bracket.bestOf", {
                            count:
                              rounds.winners[roundIndex]?.seeds[0].matchesId
                                .length,
                          })}
                        </div>
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </div>
          {/*</Draggable>*/}
          <div className="space-divider-40"></div>
          {/* Looser brackets */}
          {/*<Draggable>*/}
          <div className="columns">
            <div className="column col-12">
              {rounds.loosers &&
                rounds.loosers.length > 0 &&
                activeRounds === "loosers" && (
                  <Bracket
                    rounds={rounds.loosers}
                    renderSeedComponent={(props) => (
                      <CustomSeed
                        {...props}
                        isFullScreen={isFullScreen}
                        filteredRounds={rounds}
                      />
                    )}
                    swipeableProps={{
                      enableMouseEvents: true,
                      animateHeight: true,
                      index: tabIndex,
                      onChangeIndex: handleSwipeChange,
                    }}
                    roundTitleComponent={(
                      title: string,
                      roundIndex: number
                    ) => {
                      return (
                        <div>
                          <h5 className="skp-text-primary text-center">
                            {title}{" "}
                            {isAdmin && tournamentNotStarted && (
                              <i
                                className="las la-cog"
                                onClick={(e) =>
                                  onRoundClick(e, roundIndex, "loosers")
                                }
                              ></i>
                            )}
                          </h5>
                          <div className="skp-text-small skp-text-light text-center">
                            {rounds.loosers[roundIndex]?.seeds[0].matchesId &&
                              t("bracket.bestOf", {
                                count:
                                  rounds.winners[roundIndex]?.seeds[0].matchesId
                                    .length,
                              })}
                            {!rounds.loosers[roundIndex]?.seeds[0].matchesId &&
                              t("bracket.bestOf", { count: 1 })}
                          </div>
                        </div>
                      );
                    }}
                  />
                )}
            </div>
          </div>
          {/*</Draggable>*/}
          <div className="space-divider-40"></div>
          {/* Login Button */}
          {!KeyCloackService.isLoggedIn() && (
            <div className="columns">
              <div className="column col-12 text-center">
                <div className="skp-text-light">{t("bracket.mustLogin")}</div>
                <div className="space-divider-20"></div>
                <button
                  className="skp-btn skp-btn-primary"
                  onClick={props.onLogin}
                >
                  {t("common.login")}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <Modal
        show={showRoundModal.show}
        size={"small"}
        onClose={() =>
          setShowRoundModal({ show: false, roundIndex: 0, rounds: [] })
        }
      >
        <ConfigBracketRoundModal
          tournament={tournament}
          roundIndex={+showRoundModal.roundIndex!}
          rounds={showRoundModal.rounds!}
          refreshBracket={refreshBracket}
          closeModal={onCloseModal}
        ></ConfigBracketRoundModal>
      </Modal>
    </>
  );
};

export default BracketsTab;
