import React, { useState, useEffect, useContext } from "react";
import Match from "../Match";
import MatchService from "../../../../../../../services/MatchService";
import KeyCloackService from "../../../../../../../services/KeyCloackService";
import { toast } from "react-toastify";
import "./matchdetails-modal.scss";
import IssuesTab from "./MatchDetailsModalTabs/IssuesTab";
import ScreenshotsTab from "./MatchDetailsModalTabs/ScreenshotsTab";
import MatchRefreshButton from "./MatchRefreshButton";

// import MatchChangePlayer from "../../UiLibrary/MatchComponents/MatchChangePlayer";
// import MatchChangeTeam from "../../UiLibrary/MatchComponents/MatchChangeTeam";
import ITournament from "../../../../../../Interfaces/Tournament";
import IMatch from "../../../../../../Interfaces/Match";
import EMatchStatus from "../../../../../../../enums/MatchStatus";
import { UserContext } from "../../../../../../Context/UserContext";
import MatchScore from "../MatchScore";
import { useTranslation } from "react-i18next";

type Props = {
  tournament: ITournament;
  matchId: string;
};

function MatchDetailsModal({ tournament, matchId, ...props }: Props) {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [singleMatch, setSingleMatch] = useState<IMatch>();
  const [isPlayerHome, setIsPlayerHome] = useState(false);
  const [isPlayerAway, setIsPlayerAway] = useState(false);
  const { t } = useTranslation();

  const [tabsController, setTabController] = useState({ tab: "screenshot" });
  const isTournamentAdmin: boolean = tournament?.admins?.includes(user?.id!);
  // const isAdmin =
  //   KeyCloackService.hasRole(["Tournament Admin"]) &&
  //   (tournament?.owner === user?.id || isTournamentAdmin);
  //const isAdmin = KeyCloackService.hasRole(["Tournament Admin"] || isTournamentAdmin);
  const isAdmin =
    tournament?.owner === user?.id ||
    isTournamentAdmin ||
    (user?.roles && user.roles.includes("TournamentAdmin"))
      ? true
      : false;

  const matchCompleted: boolean = singleMatch?.status === 3;
  const matchAwaitConfirmation: boolean = singleMatch?.status === 2;
  const matchStarted: boolean = singleMatch?.status === 1;
  const matchToBePlayed: boolean = singleMatch?.status === 0;

  const tournamentCompleted: boolean = tournament?.status === 2;
  const tournamentHasStarted: boolean = tournament?.status === 1;
  const tournamentNotStarted: boolean = tournament?.status === 0;

  const canWatchInfo: boolean = isPlayerAway || isPlayerHome || isAdmin;

  const canEditChat: boolean =
    canWatchInfo && tournamentHasStarted && !tournamentCompleted;

  const canNotEditMatchWhenCompleted: boolean =
    canEditChat &&
    !matchCompleted &&
    singleMatch?.result?.scores[0].teamId !== null &&
    singleMatch?.result?.scores[1].teamId !== null;

  useEffect(() => {
    MatchService.getMatchDetails(matchId).then((match) => {
      setSingleMatch(match);

      setIsPlayerHome(
        match.result.scores[0]?.playerResults?.some(
          (playerResult) => playerResult?.playerId === user?.id
        ) || false
      );

      setIsPlayerAway(
        match.result.scores[1]?.playerResults?.some(
          (playerResult) => playerResult?.playerId === user?.id
        ) || false
      );
    });
    return () => {
      setSingleMatch(undefined);
      setIsPlayerHome(false);
      setIsPlayerAway(false);
      setTabController({
        tab: "screenshot",
      });
    };
  }, [matchId, user]);

  const reportScore = async (values: any) => {
    if (!singleMatch) {
      return;
    }

    setLoading(true);
    const teamHomeScore = { score: Number(values.teamHomeScore) };
    const teamAwayScore = { score: Number(values.teamAwayScore) };

    const teamHomePromise = await MatchService.updateMatchScoreMedia(
      matchId,
      singleMatch?.result?.scores[0].teamId,
      singleMatch?.result?.scores[0].playerResults[0].playerId,
      teamHomeScore
    );
    const teamAwayPromise = await MatchService.updateMatchScoreMedia(
      matchId,
      singleMatch?.result?.scores[1].teamId,
      singleMatch?.result?.scores[1].playerResults[0].playerId,
      teamAwayScore
    );

    Promise.all([teamHomePromise, teamAwayPromise]).then(() => {
      MatchService.updateMatch(matchId, { status: 2 }).then((match) => {
        setSingleMatch(match);
        setLoading(false);
      });
    });
  };

  const updateStatus = (status: number) => {
    if (!singleMatch) {
      return;
    }

    let data = { status: status, winner: "" };

    if (
      Number(singleMatch?.result.scores[0].score) !==
      Number(singleMatch?.result.scores[1].score)
    ) {
      let winner;
      if (status === 3) {
        if (
          singleMatch?.result.scores[0].score >
          singleMatch?.result.scores[1].score
        ) {
          winner = singleMatch?.result.scores[0].teamId;
        } else {
          winner = singleMatch?.result.scores[1].teamId;
        }
        data.winner = winner;
      }
    }

    MatchService.updateMatch(matchId, data)
      .then((match) => {
        setSingleMatch(match);
        toast.info(`Match updated to ${EMatchStatus[match.status]}`);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
      });
  };

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12 text-center">
          <div className={`skp-chip status-${singleMatch?.status}`}>
            {singleMatch && t(EMatchStatus[singleMatch.status])}
          </div>
          <div className="space-divider-20"></div>
        </div>
      </div>

      {/* IS NOT ADMIN */}
      {!isAdmin && <Match matchId={matchId} tournament={tournament} />}

      {/* IS ADMIN */}
      {isAdmin && (
        <>
          {/* TOURNAMENT NOT STARTED, MATCH COMPLETED OR AWAIT CONFIRMATION, TOURNAMENT COMPLETED */}
          {(tournamentNotStarted ||
            matchCompleted ||
            matchAwaitConfirmation ||
            tournamentCompleted ||
            matchToBePlayed) && (
            <>
              <Match matchId={matchId} tournament={tournament} />
            </>
          )}

          {/* TOURNAMENT STARTED */}
          {tournamentHasStarted && singleMatch && (
            <>
              {/* MATCH TO BE PLAYED */}
              {matchToBePlayed && (
                <>
                  {tournament?.playerType === 0 && (
                    <></>
                    // <MatchChangePlayer
                    //   match={singleMatch}
                    //   tournament={tournament}
                    //   onSetMatch={(match: IMatch) => setSingleMatch(match)}
                    // ></MatchChangePlayer>
                  )}
                  {tournament?.playerType === 1 && (
                    <></>
                    // <MatchChangeTeam
                    //   match={singleMatch}
                    //   tournament={tournament}
                    //   onSetMatch={(match: IMatch) => setSingleMatch(match)}
                    // ></MatchChangeTeam>
                  )}
                </>
              )}
              {/* MATCH STARTED */}
              {matchStarted && (
                <>
                  <MatchScore
                    match={singleMatch}
                    isAdmin={isAdmin}
                    onReportScore={reportScore}
                    loading={loading}
                    tournament={tournament}
                  />
                </>
              )}
            </>
          )}
        </>
      )}

      <div className="space-divider-20"></div>

      <MatchRefreshButton
        matchId={matchId}
        match={singleMatch}
        onSetMatch={(match: IMatch | undefined) => setSingleMatch(match)}
        timeInterval={10000}
      />

      {/* Tabs Header */}
      <div className="columns tabs-header">
        <div className="column col-12">
          <ul className="tab skp-text-light">
            <li
              className={`tab-item ${
                tabsController.tab === "screenshot" && "active"
              }`}
            >
              <a onClick={() => setTabController({ tab: "screenshot" })}>
                Screenshot
              </a>
            </li>
            <li
              className={`tab-item ${
                tabsController.tab === "issues" && "active"
              }`}
            >
              {singleMatch && (
                <>
                  {canWatchInfo ? (
                    <a
                      onClick={() => setTabController({ tab: "issues" })}
                      className={
                        singleMatch?.result?.reports?.length > 0 ? "badge" : ""
                      }
                      data-badge={Number(
                        singleMatch?.result?.reports?.length || 0
                      )}
                    >
                      <i
                        className="lar la-comment-dots"
                        style={{ fontSize: 20, verticalAlign: "middle" }}
                      ></i>{" "}
                      Chat
                    </a>
                  ) : (
                    <a onClick={() => setTabController({ tab: "issues" })}>
                      <i
                        className="lar la-comment-dots"
                        style={{ fontSize: 20, verticalAlign: "middle" }}
                      ></i>{" "}
                      Chat
                    </a>
                  )}
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
      {/* END Tabs Header */}
      {/* Screenshots TAB */}
      {tabsController.tab === "screenshot" && (
        <ScreenshotsTab
          matchId={matchId}
          tournament={tournament}
          match={singleMatch}
          canWatchInfo={canWatchInfo}
          canEditMatch={canNotEditMatchWhenCompleted}
          onSetMatch={(match: IMatch) => setSingleMatch(match)}
          isPlayerHome={isPlayerHome}
          isPlayerAway={isPlayerAway}
        />
      )}
      {/* END Screenshot tab */}

      {/* Issues TAB */}
      {tabsController.tab === "issues" && (
        <IssuesTab
          matchId={matchId}
          tournament={tournament}
          match={singleMatch}
          canWatchInfo={canWatchInfo}
          canEditChat={canEditChat}
          onSetMatch={(match: IMatch | undefined) => setSingleMatch(match)}
          isPlayerHome={isPlayerHome}
          isPlayerAway={isPlayerAway}
        />
      )}

      {/* Footer */}
      {canNotEditMatchWhenCompleted && (
        <div className="columns">
          <div className="column col-12 text-center">
            <div className="space-divider-40"></div>

            {isAdmin && !(singleMatch?.status === 0) && (
              <button
                onClick={() => updateStatus(0)}
                className="skp-btn skp-btn-secondary m-2"
              >
                To be played
              </button>
            )}
            {canNotEditMatchWhenCompleted &&
              singleMatch?.status !== 1 &&
              singleMatch?.status !== 2 && (
                <button
                  onClick={() => updateStatus(1)}
                  className="skp-btn skp-btn-primary m-2"
                >
                  Start Match
                </button>
              )}
            {isAdmin && !(singleMatch?.status === 2) && (
              <button
                onClick={() => updateStatus(2)}
                className="skp-btn skp-btn-accent m-2"
              >
                Await Moderation
              </button>
            )}
            {isAdmin && (
              <button
                onClick={() => updateStatus(3)}
                className="skp-btn skp-btn-ternary m-2"
                disabled={
                  !singleMatch?.result?.scores[0].score &&
                  !singleMatch?.result?.scores[1].score
                }
              >
                Mark As Complete
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MatchDetailsModal;
