import { RenderSeedProps, Seed, SingleLineSeed } from "react-brackets";
import SingleMatch from "./MatchComponents/SingleMatch";
import EventBus from "react-native-event-bus";
import MatchStatus from "./MatchComponents/MatchStatus";
import VisibilitySensor from "react-visibility-sensor";
import { useState, useContext, useEffect, useRef } from "react";
import { TournamentMatchesContext } from "../Context/TournamentMatchesContext";
import GenerateBracketRounds from "../Hooks/GenerateBracketRounds";
import MatchResultService from "../../services/MatchResultService";
import EMatchStatus from "../../enums/MatchStatus";
import MatchLevel from "../../enums/MatchLevel";
import { EventsContext } from "../Context/EventsContext";
import MatchService from "../../services/MatchService";
import IMatch from "../Interfaces/Match";
import { useTranslation } from "react-i18next";

const matchCache = new Map();

const viewDetails = (details: any) => {
  EventBus.getInstance().fireEvent("SHOW_MATCH_DETAILS", {
    ...details,
  });
};

const CustomSeed = ({
  seed,
  breakpoint,
  roundIndex,
  seedIndex,
  isFullScreen = false,
  filteredRounds,
}: any) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { tournamentMatches } = useContext(TournamentMatchesContext);
  const rounds = filteredRounds
    ? filteredRounds
    : GenerateBracketRounds.generate(tournamentMatches?.tournament?.matches);
  const [multipleMatchStatus, setMultipleMatchStatus] = useState<number>(
    MatchResultService.getMultipleMatchStatus(tournamentMatches?.matches, seed)
  );
  const [match, setMatch] = useState<IMatch>(matchCache.get(seed.matchesId[0]));

  const { socket, isConnected } = useContext(EventsContext);
  const isFirstLoad = useRef(true); // Usato per evitare chiamate ripetute

  let selectedRound = rounds.winners.find((round) => round.title === seed.round)
    ? rounds.winners
    : rounds.loosers;
  let isLineConnector =
    selectedRound[roundIndex]?.seeds.length ===
    selectedRound[roundIndex + 1]?.seeds.length;
  const inverti =
    seed?.matchLevel === MatchLevel.DOUBLE_BRACKET && !isLineConnector
      ? "skp-brackets-seed-left"
      : "";
  const Wrapper = isLineConnector ? SingleLineSeed : Seed;
  const multipleMatch = seed?.matchesId?.length > 1;

  const hasNext = selectedRound[roundIndex + 1] ? true : false;

  useEffect(() => {
    const matchId = seed.matchesId[0];
    if (isConnected && socket && seed.matchesId[0]) {
      socket.onAny((event, ...args) => {
        if (event === seed.matchesId[0] && args.toString() === "refresh") {
          MatchService.getMatches(seed.matchesId)
            .then((Rmatches) => {
              setMultipleMatchStatus(
                MatchResultService.getMultipleMatchStatusNoSeed(Rmatches)
              );
              // Aggiorna il singolo match se necessario
              return MatchService.getSingleMatch(matchId);
            })
            .then((Rmatch) => {
              if (Rmatch) {
                matchCache.set(matchId, Rmatch); // Memorizza il match nella cache
                setMatch(Rmatch);
              }
            })
            .catch((error) => {
              console.error("Errore durante il recupero dei match:", error);
            });
        } else if (
          event === seed.matchesId[0] &&
          args.toString() !== "update"
        ) {
          const matchElement = args[0];
          // Controlla se lo stato di match esiste
          if (matchElement && matchElement.status) {
            setMatch((prevMatch) => {
              const updatedMatch = {
                ...prevMatch,
                status: matchElement.status,
              };

              // Aggiorna la cache con il nuovo stato del match
              matchCache.set(matchId, updatedMatch);

              return updatedMatch;
            });
          }
        }
      });

      return () => {
        socket.offAny();
      };
    }
  }, [seed.matchesId[0], isConnected, socket]);

  /* const handleSocketData = (data: any, seed: any, matchId: string, setMatch: any, setMultipleMatchStatus: any, socket: any) => {
    if (data) {
      console.log("ricevo un evento ");
      console.log(data);
      // Aggiorna lo stato di più partite
      MatchService.getMatches(seed.matchesId)
        .then((Rmatches) => {
          setMultipleMatchStatus(MatchResultService.getMultipleMatchStatusNoSeed(Rmatches));
          // Aggiorna il singolo match se necessario
          return MatchService.getSingleMatch(matchId);
        })
        .then((Rmatch) => {
          if (Rmatch) {
            matchCache.set(matchId, Rmatch);  // Memorizza il match nella cache
            setMatch(Rmatch);
          }
        })
        .catch((error) => {
          console.error("Errore durante il recupero dei match:", error);
          socket.off(matchId); // Rimuovi il listener in caso di errore
        });
    }
  };

  useEffect(() => {
    if (seed.matchesId[0] && isConnected && socket && !match && visible && isFirstLoad.current) {
      console.log("dentro")
      isFirstLoad.current = false;  // Imposta il flag per evitare chiamate successive

      const matchId = seed.matchesId[0];
      
      
      console.log(`Registrando listener per matchId: ${matchId}`);
      //socket.on(matchId, handleSocketData);
      
      socket.on(matchId, (data)=>{
        console.log("dentro qui")
        console.log(data)
      });



      // Recupera il match iniziale
      MatchService.getSingleMatch(matchId).then((Rmatch) => {
        matchCache.set(matchId, Rmatch);  // Memorizza il match nella cache
        setMatch(Rmatch);
      });

      return () => {
        socket.off(matchId, handleSocketData);
      };
    }
  }, [seed.matchesId[0], isConnected, socket, match, visible]);
  */
  useEffect(() => {
    const matchId = seed.matchesId[0];
    if (matchId && visible && !match) {
      // Recupera il match iniziale solo una volta
      MatchService.getSingleMatch(matchId).then((Rmatch) => {
        matchCache.set(matchId, Rmatch); // Memorizza il match nella cache
        setMatch(Rmatch);
      });
    }
  }, [seed.matchesId[0], visible, match]);

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ top: 10, left: 10 }}
      delayedCall={true}
      onChange={(isVisible: boolean) => {
        setVisible(isVisible);
      }}
    >
      <Wrapper
        mobileBreakpoint={breakpoint}
        style={{ fontSize: 12 }}
        className={
          !isFullScreen
            ? hasNext
              ? `skp-brackets-seed ${inverti}`
              : `skp-brackets-seed-hide-line ${inverti}`
            : `${inverti}`
        }
      >
        {!isFullScreen && (
          <div
            className="skp-text-label float-left mb-1 skp-text-light skp-link-primary"
            id={`seed-${seed.id}`}
            onClick={() => viewDetails(seed)}
          >
            Match {seed.id}
          </div>
        )}
        {isFullScreen && (
          <div
            className="skp-text-label float-left skp-link-primary"
            id={`seed-${seed.id}`}
            onClick={() => viewDetails(seed)}
            style={{ position: "absolute", left: "5px", marginBottom: "15px" }}
          >
            {seed?.matchesId && seed?.matchesId?.length === 1 && (
              <span className={`color-${match?.status}`}>{seed.id}</span>
            )}
            {multipleMatch && (
              <span className={`color-${multipleMatchStatus}`}>{seed.id}</span>
            )}
          </div>
        )}

        <SingleMatch
          matchId={seed?.matchesId ? seed?.matchesId[0] : seed?.matchId}
          seed={seed}
          visible={visible}
          match={match}
        />
        {!isFullScreen && (
          <div className="columns col-gapless skp-brackets-seed-details skp-text-label">
            <div
              className="skp-link-primary column col-5 col-gapless text-left skp-text-light"
              onClick={() => viewDetails(seed)}
            >
              {seed?.matchesId && seed?.matchesId?.length === 1 && (
                <div className="skp-brackets-seed-status text-left mb-1">
                  <span className={`skp-chip status-${match?.status}`}>
                    {match && t(EMatchStatus[match.status])}
                  </span>
                </div>
              )}
              {multipleMatch && (
                <div className="skp-brackets-seed-status text-left mb-1">
                  <span className={`skp-chip status-${multipleMatchStatus}`}>
                    {t(EMatchStatus[multipleMatchStatus])}
                  </span>
                </div>
              )}
            </div>

            <div className="column col-7 text-right">
              <span
                className="skp-link-primary"
                onClick={() => viewDetails(seed)}
              >
                {t("matches.enterMatch")}
              </span>
            </div>
          </div>
        )}
      </Wrapper>
    </VisibilitySensor>
  );
};

export default CustomSeed;
