import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useNavigate, useParams } from "react-router-dom";
import IFileBase64Image from "../../Interfaces/FileBase64Image";
import ICompetition from "../../Interfaces/Competition";
import TournamentService from "../../../services/TournamentService";
import { useTranslation } from "react-i18next";
import UploadButton from "../../UiLibrary/UploadButton/UploadButton";
import Marquee from "react-text-marquee";
import OverviewTab from "./OverviewTab";
import TournamentTab from "./TournamentTab";

const CompetitionPage = () => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const params = useParams<{ id: string; tab: string }>();
  const competitionId = params.id;
  const currentTab = params.tab;
  const [competition, setCompetition] = useState<ICompetition>();
  const [imageToUpload, setImageToUpload] = useState<IFileBase64Image>();

  const [showUploadModal, setShowUploadModal] = useState<{
    show: boolean;
    type?: string | undefined;
  }>({
    show: false,
    type: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const isSettingsPage = false;

  const tabs = [
    { name: t("tabs.overview"), url: "overview" },
    { name: t("common.tournaments"), url: "tournaments" },
    { name: t("tabs.ranking"), url: "ranking" },
  ];

  const filteredTabs =
    competition?.showRanking !== false
      ? tabs
      : tabs.filter((tab) => tab.url !== "ranking");

  useEffect(() => {
    if (!currentTab) {
      navigate(`/competitions/${competitionId}/overview`);
    }
    TournamentService.getCompetition(competitionId!).then((response) => {
      setCompetition(response);

      setIsAdmin(
        user &&
          (competition?.owner === user?.id ||
            competition?.admins?.includes(user?.id!) ||
            (user?.roles && user.roles.includes("TournamentAdmin")))
          ? true
          : false
      );
    });
  }, [competitionId, currentTab, navigate]);

  const uploadPicture = async (image: IFileBase64Image, type: string) => {};

  const dynamicBackground = {
    backgroundImage:
      competition?.brandSlug !== undefined &&
      competition?.brandSlug === "eseriea"
        ? 'url("https://storage.googleapis.com/skillpro-bucket/qckEVfQvMkshSaF.png")'
        : "none",
    backgroundSize: "cover", // Per adattare l'immagine all'intero div
    backgroundPosition: "center", // Per centrare l'immagine
  };

  console.log(isAdmin);

  return (
    <>
      <Scrollbars
        renderView={(props: any) => <div {...props} id="scrollableDiv" />}
        style={dynamicBackground}
      >
        <div className="skp-page-with-image-banner">
          {isAdmin && (
            <UploadButton
              type="banner"
              loading={loading}
              onUpdateImage={(image) => uploadPicture(image, "profile_banner")}
            >
              <button className="upload-banner-btn skp-btn skp-btn-secondary skp-text-default">
                <i className="las la-camera"></i> {t("component.uploadBanner")}
              </button>
            </UploadButton>
          )}
          <div className="skp-image-banner">
            {competition && competition?.banner && !imageError && (
              <img
                alt="profile-banner"
                src={competition?.banner}
                onError={() => setImageError(true)}
              />
            )}
            <div className="transparent-black"></div>
          </div>

          {/* PAGE TOP */}
          <div className="container page-top">
            <div className="columns page-top">
              <div className="column col-8 col-xs-12 text-center-xs">
                <span className="skp-text-label skp-text-light">
                  {t("common.competition")}
                </span>{" "}
                <br></br>
                <h1 className="skp-text-primary skp-heading-no-margin">
                  <Marquee text={competition?.name || ""} />
                </h1>
                {isAdmin && (
                  <>
                    {!isSettingsPage && (
                      <Link
                        to={`/competitions/${competitionId}/settings/general`}
                        className="skp-link skp-text-small"
                      >
                        {t("common.settings")} <i className="las la-cog"></i>
                      </Link>
                    )}
                    {isSettingsPage && (
                      <Link
                        to={`/competitions/${competitionId}/overview`}
                        className="skp-link skp-text-small"
                      >
                        <i className="las la-arrow-left"></i>{" "}
                        {t("tournament.viewTournament")}
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* END PAGE TOP */}
          <div className="space-divider-5"></div>
          {/* TABS HEADER */}
          <div className="container tabs-header">
            <div className="columns">
              <div className="column col-12">
                <ul className="tab skp-text-light">
                  {filteredTabs.map((tab) => (
                    <li
                      key={tab.name}
                      className={
                        currentTab === tab.url ? "tab-item active" : "tab-item"
                      }
                    >
                      <Link
                        to={`/competitions/${competitionId}/${tab.url}`}
                        className={`${currentTab === tab.url && "active"}`}
                      >
                        {tab.name}{" "}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* END TABS HEADER */}
          {/* TABS BODY */}
          <div className="container tabs-body">
            <div className="columns">
              {currentTab === filteredTabs[0].url && (
                <OverviewTab competition={competition} />
              )}
              {currentTab === filteredTabs[1].url && (
                <TournamentTab competitionId={competitionId} />
              )}
            </div>
          </div>
          {/* END TABS BODY */}
        </div>
      </Scrollbars>
    </>
  );
};

export default CompetitionPage;
